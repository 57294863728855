<template>
    <div>
        <v-text-field style="width:100px;" v-model.number="yw[0]" type="number" label="Year" />
        <v-text-field style="width:100px;" v-model.number="yw[1]" type="number" label="Week" />
        <upload-form
            ref="form"
            mode="products_master"
            :yw="yw"
            :duct="duct"
            />
        <upload-form
            ref="form"
            mode="customers_master"
            :yw="yw"
            :duct="duct"
            />
        <snackbar ref="snackbar" />
    </div>
</template>
<script>
import UploadForm from './UploadForm'
import Snackbar from '@/components/ui/Snackbar'

export default {
    components: {
        UploadForm,
        Snackbar
    },
    data: () => ({
        yw: [2021, 30],
    }),
    props: ["duct"],
    mounted() {
        this.duct.invokeOnOpen(() => {
            this.duct.addEventHandler(this.duct.EVENT.FORECAST_UPLOAD_PRODUCTS_MASTER, {
                success: (rid,eid,data) => {
                    console.log(data);
                    this.$refs.snackbar.show('success', 'アップロード成功');
                },
                error: (rid,eid,data) => {
                    console.error(data);
                    this.$refs.snackbar.show('error', 'アップロード失敗');
                }
            });
        });
    }
}
</script>
